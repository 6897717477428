import { CustomSession } from "~/types/Auth";

type DatAdsApiFetchOptions = {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "OPTIONS" | "HEAD";
  headers?: HeadersInit | undefined;
  body?: BodyInit | Record<string, any> | null | undefined;
  params?: Record<string, any>;
  query?: Record<string, any>;
};

type DatAdsApiFetchOptionsWithBaseUrl = DatAdsApiFetchOptions & {
  baseURL: string;
};

const doFetch = async <T>(
  url: string,
  options: DatAdsApiFetchOptionsWithBaseUrl,
) => {
  try {
    const response = await $fetch<T>(url, options);
    return {
      data: ref(response),
      error: ref(null),
    };
  } catch (error) {
    return {
      data: ref(null),
      error: ref(error as Error),
    };
  }
};

export const useDatAdsApiFetch = async <T>(
  url: string,
  options: DatAdsApiFetchOptions = {},
) => {
  const {
    public: { apiBaseUrl },
  } = useRuntimeConfig();
  const { data, getSession } = useAuth();

  if (data.value == null) {
    // eslint-disable-next-line no-console
    console.warn("Session not found");
    return doFetch<T>(url, {
      ...options,
      baseURL: apiBaseUrl,
    });
  }

  let sessionWithToken = data.value as CustomSession;
  if (new Date(sessionWithToken.expires).getTime() < Date.now()) {
    // eslint-disable-next-line no-console
    console.warn(
      "[useDatAdsApiFetch] Access token has expired, trying to refresh it",
      sessionWithToken.expires,
    );
    sessionWithToken = await getSession();
  }

  if (sessionWithToken.access_token == null) {
    return {
      data: ref(null),
      error: ref(new Error("No access token")),
    };
  }

  return doFetch<T>(url, {
    ...options,
    baseURL: apiBaseUrl,
    headers: {
      ...(options.headers || {}),
      Authorization: `Bearer ${sessionWithToken.access_token}`,
    },
  });
};
