import { GroupBy, Provider, Query, Timeframe } from "./shared";

export interface StatElement {
  count: number;
  metrics: {
    [metric: string]: {
      curValue: number | null;
      compareValue: number | null;
    };
  };
}

export type Stat = Array<StatElement>;

export interface BreakdownElement<T extends number | string> {
  displayName: string;
  breakdown: T;
  count: number;
  ids: Array<number>;
  adGroupAdDate: string | null;
  tags: Array<number>;
  metrics: {
    [metric: string]: {
      curValue: number | null;
      compareValue: number | null;
    };
  };
}

export type Breakdown<T extends number | string> = {
  totalData: Array<BreakdownElement<T>>;
  totalCount: number;
  totalBreakdowns: Array<T>;
  totalIds: Array<number>;
};

export interface ChartEntry {
  startTime: number;
  metrics: {
    [metric: string]: number[];
  };
}

export type Chart = Array<ChartEntry>;

export enum InsightsType {
  stat = "stat",
  breakdown = "breakdown",
  chart = "chart",
}

export type MetricInfo = {
  name: string;
  curValue: number;
  compareValue: number;
  relPosition: number;
  absPosition: number;
};

export interface GetInsightsDto {
  type: InsightsType;
  provider?: Provider | undefined | null;
  metrics?: Array<string> | undefined | null;
  timeframe?: Timeframe | undefined | null;
  startDate?: string | undefined | null;
  endDate?: string | undefined | null;
  clientId?: number | undefined | null;
  filter?: Array<Array<MetricsFilter>> | undefined | null;
  groupBy?: GroupBy | undefined | null;
  accumulate?: boolean | undefined | null;
}

export interface GetUniqueInsightsDto extends GetInsightsDto {
  id: string;
}

export interface GetInsightsBatchDto {
  requests: Array<GetUniqueInsightsDto>;
}

export interface BaseAdFilter {
  adAccountId?: Query<string> | undefined | null;
  adCampaignId?: Query<string> | undefined | null;
  adGroupId?: Query<string> | undefined | null;
  adGroupAdId?: Query<string> | undefined | null;
  adGroupAdPrimaryId?: Query<number> | undefined | null;
  adAccountName?: Query<string> | undefined | null;
  adCampaignName?: Query<string> | undefined | null;
  adGroupName?: Query<string> | undefined | null;
  adGroupAdName?: Query<string> | undefined | null;
  provider?: Query<string> | undefined | null;
  creativeId?: Query<string> | undefined | null;
  description?: Query<string> | undefined | null;
  headline?: Query<string> | undefined | null;
  landingPage?: Query<string> | undefined | null;
  postId?: Query<string> | undefined | null;
  adGroupAdTag?: Query<number> | undefined | null;
}

export type MetricsFilter = BaseAdFilter & {
  [metric in string]?: Query<number> | Query<string> | undefined | null;
};

export type CustomMetricSort = "asc" | "desc";

export enum AggregateFunction {
  SUM = "SUM",
  AVG = "AVG",
  WEIGHTED_AVG = "WEIGHTED_AVG",
  COST_PER_ACTION = "COST_PER_ACTION",
  CUSTOM_RATIO = "CUSTOM_RATIO",
  EXPRESSION = "EXPRESSION",
}

export type ValueFormatter =
  | "currency"
  | "integer"
  | "number"
  | "percentage"
  | "decimalPercentage" // Deprecated: Use "percentage" instead
  | "decimalAsPercentage" // Refers to a decimal value that should be displayed as a percentage (i.e. 5 as 5%)
  | "seconds";

export type Operator = "+" | "-" | "*" | "/";

export interface Operand<T extends number | string> {
  type: "operand";
  value: T;
}

export interface BinaryOperation<T extends number | string> {
  type: "binary";
  operator: Operator;
  left: Expression<T>;
  right: Expression<T>;
}

export type Expression<T extends number | string> =
  | Operand<T>
  | BinaryOperation<T>;
export type NumericExpression = Expression<number>;
export type ColumnExpression = Expression<string>;

export type CustomMetricRule = {
  customMetricRuleId: number | null;
  metricName: string;
  metricDisplayName: string;
  description: string | null;
  aggregateFunction: AggregateFunction;
  numerator: string;
  denominator: string;
  weightedBy: string;
  formatter: ValueFormatter;
  sort: CustomMetricSort;
  expression: ColumnExpression | null;
  provider: Provider; // Target provider i.e. Meta | TikTok | Google
  sourceProvider: Provider | null; // Source provider i.e. adtribute | Hyros | Tracify | null when db custom rule
};

export type CustomMetricRuleResponse = {
  id: number;
  title: string;
  description: string | null;
  expression: ColumnExpression;
  createdAt: string;
  creator: number;
  clientId: number;
  formatter: ValueFormatter;
  sort: CustomMetricSort;
  provider: Provider; // Target provider i.e. Meta | TikTok | Google
  sourceProvider: Provider | null; // Source provider i.e. adtribute | Hyros | Tracify | null when db custom rule
};

export const CUSTOM_METRIC_NAME_PREFIX = "custom_";

export type AdsMetric = TiktokMetric | MetaMetric | GoogleMetric;

export enum ScoreMetric {
  HookScore = "HookScore",
  WatchScore = "WatchScore",
  ClickScore = "ClickScore",
  ConversionScore = "ConversionScore",
}

export enum MiscMetric {
  ad_count = "ad_count",
  revenue_per_ad = "revenue_per_ad",
}

export const MISC_METRIC_TO_FORMATTER: Record<MiscMetric, ValueFormatter> = {
  ad_count: "integer",
  revenue_per_ad: "currency",
};

export const MISC_METRIC_TO_PERCENTAGE_UP_COLOR: Record<
  MiscMetric,
  "green" | "red"
> = {
  ad_count: "green",
  revenue_per_ad: "green",
};

export enum MetaMetric {
  spend = "spend",
  reach = "reach",
  impressions = "impressions",
  frequency = "frequency",
  cpm = "cpm",
  clicks = "clicks",
  cpc = "cpc",
  ctr = "ctr",
  ctr_all = "ctr_all",
  purchases = "purchases",
  cost_per_purchase = "cost_per_purchase",
  purchase_roas = "purchase_roas",
  purchase_conversion_value = "purchase_conversion_value",
  view_content = "view_content",
  cost_per_view_content = "cost_per_view_content",
  add_to_cart = "add_to_cart",
  cost_per_add_to_cart = "cost_per_add_to_cart",
  initiate_checkout = "initiate_checkout",
  cost_per_initiate_checkout = "cost_per_initiate_checkout",
  add_payment_info = "add_payment_info",
  cost_per_add_payment_info = "cost_per_add_payment_info",
  video_plays = "video_plays",
  video_plays_2sec = "video_plays_2sec",
  video_plays_3sec = "video_plays_3sec",
  video_avg_play_time = "video_avg_play_time",
  video_views_p25 = "video_views_p25",
  video_views_p50 = "video_views_p50",
  video_views_p75 = "video_views_p75",
  video_views_p100 = "video_views_p100",
  thumb_stop_ratio = "thumb_stop_ratio",
  datads_thumb_stop_ratio = "datads_thumb_stop_ratio",
  clicks_per_video_play = "clicks_per_video_play",
  view_content_per_link_click = "view_content_per_link_click",
  add_to_cart_per_view_content = "add_to_cart_per_view_content",
  initiate_checkout_per_add_to_cart = "initiate_checkout_per_add_to_cart",
  add_payment_info_per_add_to_cart = "add_payment_info_per_add_to_cart",
  purchase_per_add_to_cart = "purchase_per_add_to_cart",
  app_install = "app_install",
  cost_per_app_install = "cost_per_app_install",
  complete_registration = "complete_registration",
  cost_per_complete_registration = "cost_per_complete_registration",
  contact = "contact",
  cost_per_contact = "cost_per_contact",
  customize_product = "customize_product",
  cost_per_customize_product = "cost_per_customize_product",
  donate = "donate",
  cost_per_donate = "cost_per_donate",
  find_location = "find_location",
  cost_per_find_location = "cost_per_find_location",
  lead = "lead",
  cost_per_lead = "cost_per_lead",
  schedule = "schedule",
  cost_per_schedule = "cost_per_schedule",
  search = "search",
  cost_per_search = "cost_per_search",
  start_trial = "start_trial",
  cost_per_start_trial = "cost_per_start_trial",
  submit_application = "submit_application",
  cost_per_submit_application = "cost_per_submit_application",
  subscribe = "subscribe",
  cost_per_subscribe = "cost_per_subscribe",
  add_to_wishlist = "add_to_wishlist",
  cost_per_add_to_wishlist = "cost_per_add_to_wishlist",
  hook_to_click_ratio = "hook_to_click_ratio",
  landing_page_view = "landing_page_view",
  cost_per_landing_page_view = "cost_per_landing_page_view",
  conversion_rate = "conversion_rate",
  purchase_per_clicks = "purchase_per_clicks",
  add_to_cart_per_clicks = "add_to_cart_per_clicks",
  thruplays = "thruplays",
  hold_rate = "hold_rate",
  stop_scroll_ratio = "stop_scroll_ratio",
  aov = "aov",
  outbound_clicks = "outbound_clicks",
  outbound_clicks_ctr = "outbound_clicks_ctr",
  outbound_clicks_cpc = "outbound_clicks_cpc",
  unique_outbound_clicks = "unique_outbound_clicks",
  unique_outbound_clicks_ctr = "unique_outbound_clicks_ctr",
  unique_outbound_clicks_cpc = "unique_outbound_clicks_cpc",
  video_view_rate_p100 = "video_view_rate_p100",
  video_view_rate_p25 = "video_view_rate_p25",
  video_view_rate_p50 = "video_view_rate_p50",
  video_view_rate_p75 = "video_view_rate_p75",
  video_view_rate = "video_view_rate",
  post_comment = "post_comment",
  cost_per_post_comment = "cost_per_post_comment",
  post_save = "post_save",
  cost_per_post_save = "cost_per_post_save",
  post_share = "post_share",
  cost_per_post_share = "cost_per_post_share",
  post_reaction = "post_reaction",
  cost_per_post_reaction = "cost_per_post_reaction",
  post_engagement = "post_engagement",
  cost_per_post_engagement = "cost_per_post_engagement",
  page_engagement = "page_engagement",
  cost_per_page_engagement = "cost_per_page_engagement",
  page_like = "page_like",
  cost_per_page_like = "cost_per_page_like",
  page_photo_view = "page_photo_view",
  cost_per_page_photo_view = "cost_per_page_photo_view",
}

export enum TiktokMetric {
  spend = "spend",
  reach = "reach",
  impressions = "impressions",
  frequency = "frequency",
  cpm = "cpm",
  clicks = "clicks",
  cpc = "cpc",
  ctr = "ctr",
  complete_payment = "complete_payment",
  skan_purchase = "skan_purchase",
  purchase = "purchase",
  cost_per_complete_payment = "cost_per_complete_payment",
  complete_payment_roas = "complete_payment_roas",
  total_complete_payment_rate = "total_complete_payment_rate",
  product_details_page_browse = "product_details_page_browse",
  view_content = "view_content",
  skan_view_content = "skan_view_content",
  cost_per_product_details_page_browse = "cost_per_product_details_page_browse",
  cost_per_view_content = "cost_per_view_content",
  skan_cost_per_view_content = "skan_cost_per_view_content",
  app_event_add_to_cart = "app_event_add_to_cart",
  web_event_add_to_cart = "web_event_add_to_cart",
  skan_add_to_cart = "skan_add_to_cart",
  cost_per_app_event_add_to_cart = "cost_per_app_event_add_to_cart",
  cost_per_web_event_add_to_cart = "cost_per_web_event_add_to_cart",
  skan_cost_per_add_to_cart = "skan_cost_per_add_to_cart",
  initiate_checkout = "initiate_checkout",
  checkout = "checkout",
  skan_checkout = "skan_checkout",
  cost_per_initiate_checkout = "cost_per_initiate_checkout",
  cost_per_checkout = "cost_per_checkout",
  skan_cost_per_checkout = "skan_cost_per_checkout",
  add_billing = "add_billing",
  add_payment_info = "add_payment_info",
  skan_add_payment_info = "skan_add_payment_info",
  cost_per_add_billing = "cost_per_add_billing",
  cost_per_add_payment_info = "cost_per_add_payment_info",
  skan_cost_per_add_payment_info = "skan_cost_per_add_payment_info",
  video_play_actions = "video_play_actions",
  video_watched_2s = "video_watched_2s",
  video_watched_6s = "video_watched_6s",
  average_video_play = "average_video_play",
  average_video_play_per_user = "average_video_play_per_user",
  video_views_p25 = "video_views_p25",
  video_views_p50 = "video_views_p50",
  video_views_p75 = "video_views_p75",
  video_views_p100 = "video_views_p100",
  datads_thumb_stop_ratio = "datads_thumb_stop_ratio",
  clicks_per_video_play = "clicks_per_video_play",
  view_content_per_link_click = "view_content_per_link_click",
  add_to_cart_per_view_content = "add_to_cart_per_view_content",
  initiate_checkout_per_add_to_cart = "initiate_checkout_per_add_to_cart",
  add_payment_info_per_add_to_cart = "add_payment_info_per_add_to_cart",
  purchase_per_add_to_cart = "purchase_per_add_to_cart",
  add_to_wishlist = "add_to_wishlist",
  cost_per_add_to_wishlist = "cost_per_add_to_wishlist",
  on_web_add_to_wishlist = "on_web_add_to_wishlist",
  cost_per_on_web_add_to_wishlist = "cost_per_on_web_add_to_wishlist",
  skan_add_to_wishlist = "skan_add_to_wishlist",
  cost_per_skan_add_to_wishlist = "cost_per_skan_add_to_wishlist",
  button_click = "button_click",
  cost_per_button_click = "cost_per_button_click",
  registration = "registration",
  cost_per_registration = "cost_per_registration",
  user_registration = "user_registration",
  cost_per_user_registration = "cost_per_user_registration",
  skan_registration = "skan_registration",
  cost_per_skan_registration = "cost_per_skan_registration",
  online_consult = "online_consult",
  cost_per_online_consult = "cost_per_online_consult",
  download_start = "download_start",
  cost_per_download_start = "cost_per_download_start",
  on_web_order = "on_web_order",
  cost_per_on_web_order = "cost_per_on_web_order",
  search = "search",
  cost_per_search = "cost_per_search",
  page_event_search = "page_event_search",
  cost_per_page_event_search = "cost_per_page_event_search",
  skan_search = "skan_search",
  cost_per_skan_search = "cost_per_skan_search",
  form = "form",
  cost_per_form = "cost_per_form",
  subscribe = "subscribe",
  cost_per_subscribe = "cost_per_subscribe",
  on_web_subscribe = "on_web_subscribe",
  cost_per_on_web_subscribe = "cost_per_on_web_subscribe",
  skan_subscribe = "skan_subscribe",
  cost_per_skan_subscribe = "cost_per_skan_subscribe",
  app_install = "app_install",
  cost_per_app_install = "cost_per_app_install",
  skan_app_install = "skan_app_install",
  cost_per_skan_app_install = "cost_per_skan_app_install",
  hook_to_click_ratio = "hook_to_click_ratio",
  total_landing_page_view = "total_landing_page_view",
  cost_per_total_landing_page_view = "cost_per_total_landing_page_view",
  conversion_rate = "conversion_rate",
  purchase_per_clicks = "purchase_per_clicks",
  add_to_cart_per_clicks = "add_to_cart_per_clicks",
  hold_rate = "hold_rate",
  stop_scroll_ratio = "stop_scroll_ratio",
  aov = "aov",
  video_view_rate_p100 = "video_view_rate_p100",
  video_view_rate_p25 = "video_view_rate_p25",
  video_view_rate_p50 = "video_view_rate_p50",
  video_view_rate_p75 = "video_view_rate_p75",
  video_view_rate = "video_view_rate",
  engagements = "engagements",
  cost_per_engagements = "cost_per_engagements",
  follows = "follows",
  cost_per_follows = "cost_per_follows",
  likes = "likes",
  cost_per_likes = "cost_per_likes",
  comments = "comments",
  cost_per_comments = "cost_per_comments",
  shares = "shares",
  cost_per_shares = "cost_per_shares",
  profile_visits = "profile_visits",
  cost_per_profile_visits = "cost_per_profile_visits",
  onsite_form = "onsite_form",
  cost_per_onsite_form = "cost_per_onsite_form",
}

export enum GoogleMetric {
  total_cost = "total_cost",
  conversions = "conversions",
  conversions_value = "conversions_value",
  all_conversions = "all_conversions",
  all_conversions_value = "all_conversions_value",
  average_cart_size = "average_cart_size",
  average_cpc = "average_cpc",
  average_cpe = "average_cpe",
  average_cpv = "average_cpv",
  average_cpm = "average_cpm",
  clicks = "clicks",
  ctr = "ctr",
  engagement_rate = "engagement_rate",
  engagements = "engagements",
  impressions = "impressions",
  interactions = "interactions",
  interaction_rate = "interaction_rate",
  new_customer_lifetime_value = "new_customer_lifetime_value",
  orders = "orders",
  video_views_p100 = "video_views_p100",
  video_views_p25 = "video_views_p25",
  video_views_p50 = "video_views_p50",
  video_views_p75 = "video_views_p75",
  video_view_rate_p100 = "video_view_rate_p100",
  video_view_rate_p25 = "video_view_rate_p25",
  video_view_rate_p50 = "video_view_rate_p50",
  video_view_rate_p75 = "video_view_rate_p75",
  video_view_rate = "video_view_rate",
  video_views = "video_views",
  view_through_conversions = "view_through_conversions",
  value_per_all_conversions = "value_per_all_conversions",
  value_per_conversion = "value_per_conversion",
  cost_per_all_conversions = "cost_per_all_conversions",
  cost_per_conversion = "cost_per_conversion",
  roas = "roas",
  conversions_per_clicks = "conversions_per_clicks",
}

export const metaMetricToFormatValueFunction: {
  [key in MetaMetric]: ValueFormatter;
} = {
  [MetaMetric.spend]: "currency",
  [MetaMetric.reach]: "integer",
  [MetaMetric.impressions]: "integer",
  [MetaMetric.frequency]: "number",
  [MetaMetric.cpm]: "currency",
  [MetaMetric.clicks]: "integer",
  [MetaMetric.cpc]: "currency",
  [MetaMetric.ctr]: "decimalAsPercentage",
  [MetaMetric.ctr_all]: "decimalAsPercentage",
  [MetaMetric.purchases]: "integer",
  [MetaMetric.cost_per_purchase]: "currency",
  [MetaMetric.purchase_roas]: "number",
  [MetaMetric.purchase_conversion_value]: "currency",
  [MetaMetric.view_content]: "integer",
  [MetaMetric.cost_per_view_content]: "currency",
  [MetaMetric.add_to_cart]: "integer",
  [MetaMetric.cost_per_add_to_cart]: "currency",
  [MetaMetric.initiate_checkout]: "integer",
  [MetaMetric.cost_per_initiate_checkout]: "currency",
  [MetaMetric.add_payment_info]: "integer",
  [MetaMetric.cost_per_add_payment_info]: "currency",
  [MetaMetric.video_plays]: "integer",
  [MetaMetric.video_plays_2sec]: "integer",
  [MetaMetric.video_plays_3sec]: "integer",
  [MetaMetric.video_avg_play_time]: "number",
  [MetaMetric.video_views_p25]: "integer",
  [MetaMetric.video_views_p50]: "integer",
  [MetaMetric.video_views_p75]: "integer",
  [MetaMetric.video_views_p100]: "integer",
  [MetaMetric.thumb_stop_ratio]: "percentage",
  [MetaMetric.datads_thumb_stop_ratio]: "percentage",
  [MetaMetric.clicks_per_video_play]: "percentage",
  [MetaMetric.view_content_per_link_click]: "percentage",
  [MetaMetric.add_to_cart_per_view_content]: "percentage",
  [MetaMetric.initiate_checkout_per_add_to_cart]: "percentage",
  [MetaMetric.add_payment_info_per_add_to_cart]: "percentage",
  [MetaMetric.purchase_per_add_to_cart]: "percentage",
  [MetaMetric.app_install]: "integer",
  [MetaMetric.cost_per_app_install]: "currency",
  [MetaMetric.complete_registration]: "integer",
  [MetaMetric.cost_per_complete_registration]: "currency",
  [MetaMetric.contact]: "integer",
  [MetaMetric.cost_per_contact]: "currency",
  [MetaMetric.customize_product]: "integer",
  [MetaMetric.cost_per_customize_product]: "currency",
  [MetaMetric.donate]: "integer",
  [MetaMetric.cost_per_donate]: "currency",
  [MetaMetric.find_location]: "integer",
  [MetaMetric.cost_per_find_location]: "currency",
  [MetaMetric.lead]: "integer",
  [MetaMetric.cost_per_lead]: "currency",
  [MetaMetric.schedule]: "integer",
  [MetaMetric.cost_per_schedule]: "currency",
  [MetaMetric.search]: "integer",
  [MetaMetric.cost_per_search]: "currency",
  [MetaMetric.start_trial]: "integer",
  [MetaMetric.cost_per_start_trial]: "currency",
  [MetaMetric.submit_application]: "integer",
  [MetaMetric.cost_per_submit_application]: "currency",
  [MetaMetric.subscribe]: "integer",
  [MetaMetric.cost_per_subscribe]: "currency",
  [MetaMetric.add_to_wishlist]: "integer",
  [MetaMetric.cost_per_add_to_wishlist]: "currency",
  [MetaMetric.hook_to_click_ratio]: "percentage",
  [MetaMetric.landing_page_view]: "integer",
  [MetaMetric.cost_per_landing_page_view]: "currency",
  [MetaMetric.conversion_rate]: "percentage",
  [MetaMetric.purchase_per_clicks]: "percentage",
  [MetaMetric.add_to_cart_per_clicks]: "percentage",
  [MetaMetric.hold_rate]: "percentage",
  [MetaMetric.thruplays]: "integer",
  [MetaMetric.stop_scroll_ratio]: "percentage",
  [MetaMetric.aov]: "currency",
  [MetaMetric.outbound_clicks]: "integer",
  [MetaMetric.outbound_clicks_cpc]: "currency",
  [MetaMetric.outbound_clicks_ctr]: "percentage",
  [MetaMetric.unique_outbound_clicks]: "integer",
  [MetaMetric.unique_outbound_clicks_ctr]: "decimalAsPercentage",
  [MetaMetric.unique_outbound_clicks_cpc]: "currency",
  [MetaMetric.video_view_rate_p100]: "percentage",
  [MetaMetric.video_view_rate_p25]: "percentage",
  [MetaMetric.video_view_rate_p50]: "percentage",
  [MetaMetric.video_view_rate_p75]: "percentage",
  [MetaMetric.video_view_rate]: "percentage",
  [MetaMetric.post_comment]: "integer",
  [MetaMetric.cost_per_post_comment]: "currency",
  [MetaMetric.post_save]: "integer",
  [MetaMetric.cost_per_post_save]: "currency",
  [MetaMetric.post_share]: "integer",
  [MetaMetric.cost_per_post_share]: "currency",
  [MetaMetric.post_reaction]: "integer",
  [MetaMetric.cost_per_post_reaction]: "currency",
  [MetaMetric.post_engagement]: "integer",
  [MetaMetric.cost_per_post_engagement]: "currency",
  [MetaMetric.page_engagement]: "integer",
  [MetaMetric.cost_per_page_engagement]: "currency",
  [MetaMetric.page_like]: "integer",
  [MetaMetric.cost_per_page_like]: "currency",
  [MetaMetric.page_photo_view]: "integer",
  [MetaMetric.cost_per_page_photo_view]: "currency",
};

export const tiktokMetricToFormatValueFunction: {
  [key in TiktokMetric]: ValueFormatter;
} = {
  [TiktokMetric.spend]: "currency",
  [TiktokMetric.reach]: "integer",
  [TiktokMetric.impressions]: "integer",
  [TiktokMetric.frequency]: "number",
  [TiktokMetric.cpm]: "currency",
  [TiktokMetric.clicks]: "integer",
  [TiktokMetric.cpc]: "currency",
  [TiktokMetric.ctr]: "decimalAsPercentage",
  [TiktokMetric.complete_payment]: "integer",
  [TiktokMetric.skan_purchase]: "integer",
  [TiktokMetric.purchase]: "integer",
  [TiktokMetric.cost_per_complete_payment]: "currency",
  [TiktokMetric.complete_payment_roas]: "number",
  [TiktokMetric.total_complete_payment_rate]: "currency",
  [TiktokMetric.product_details_page_browse]: "integer",
  [TiktokMetric.view_content]: "integer",
  [TiktokMetric.skan_view_content]: "integer",
  [TiktokMetric.cost_per_product_details_page_browse]: "currency",
  [TiktokMetric.cost_per_view_content]: "currency",
  [TiktokMetric.skan_cost_per_view_content]: "currency",
  [TiktokMetric.app_event_add_to_cart]: "integer",
  [TiktokMetric.web_event_add_to_cart]: "integer",
  [TiktokMetric.skan_add_to_cart]: "integer",
  [TiktokMetric.cost_per_app_event_add_to_cart]: "currency",
  [TiktokMetric.cost_per_web_event_add_to_cart]: "currency",
  [TiktokMetric.skan_cost_per_add_to_cart]: "currency",
  [TiktokMetric.initiate_checkout]: "integer",
  [TiktokMetric.checkout]: "integer",
  [TiktokMetric.skan_checkout]: "integer",
  [TiktokMetric.cost_per_initiate_checkout]: "currency",
  [TiktokMetric.cost_per_checkout]: "currency",
  [TiktokMetric.skan_cost_per_checkout]: "currency",
  [TiktokMetric.add_billing]: "integer",
  [TiktokMetric.add_payment_info]: "integer",
  [TiktokMetric.skan_add_payment_info]: "integer",
  [TiktokMetric.cost_per_add_billing]: "currency",
  [TiktokMetric.cost_per_add_payment_info]: "currency",
  [TiktokMetric.skan_cost_per_add_payment_info]: "currency",
  [TiktokMetric.video_play_actions]: "integer",
  [TiktokMetric.video_watched_2s]: "integer",
  [TiktokMetric.video_watched_6s]: "integer",
  [TiktokMetric.average_video_play]: "number",
  [TiktokMetric.average_video_play_per_user]: "number",
  [TiktokMetric.video_views_p25]: "integer",
  [TiktokMetric.video_views_p50]: "integer",
  [TiktokMetric.video_views_p75]: "integer",
  [TiktokMetric.video_views_p100]: "integer",
  [TiktokMetric.datads_thumb_stop_ratio]: "percentage",
  [TiktokMetric.clicks_per_video_play]: "percentage",
  [TiktokMetric.view_content_per_link_click]: "percentage",
  [TiktokMetric.add_to_cart_per_view_content]: "percentage",
  [TiktokMetric.initiate_checkout_per_add_to_cart]: "percentage",
  [TiktokMetric.add_payment_info_per_add_to_cart]: "percentage",
  [TiktokMetric.purchase_per_add_to_cart]: "percentage",
  [TiktokMetric.add_to_wishlist]: "integer",
  [TiktokMetric.cost_per_add_to_wishlist]: "currency",
  [TiktokMetric.on_web_add_to_wishlist]: "integer",
  [TiktokMetric.cost_per_on_web_add_to_wishlist]: "currency",
  [TiktokMetric.skan_add_to_wishlist]: "integer",
  [TiktokMetric.cost_per_skan_add_to_wishlist]: "currency",
  [TiktokMetric.button_click]: "integer",
  [TiktokMetric.cost_per_button_click]: "currency",
  [TiktokMetric.registration]: "integer",
  [TiktokMetric.cost_per_registration]: "currency",
  [TiktokMetric.user_registration]: "integer",
  [TiktokMetric.cost_per_user_registration]: "currency",
  [TiktokMetric.skan_registration]: "integer",
  [TiktokMetric.cost_per_skan_registration]: "currency",
  [TiktokMetric.online_consult]: "integer",
  [TiktokMetric.cost_per_online_consult]: "currency",
  [TiktokMetric.download_start]: "integer",
  [TiktokMetric.cost_per_download_start]: "currency",
  [TiktokMetric.on_web_order]: "integer",
  [TiktokMetric.cost_per_on_web_order]: "currency",
  [TiktokMetric.search]: "integer",
  [TiktokMetric.cost_per_search]: "currency",
  [TiktokMetric.page_event_search]: "integer",
  [TiktokMetric.cost_per_page_event_search]: "currency",
  [TiktokMetric.skan_search]: "integer",
  [TiktokMetric.cost_per_skan_search]: "currency",
  [TiktokMetric.form]: "integer",
  [TiktokMetric.cost_per_form]: "currency",
  [TiktokMetric.subscribe]: "integer",
  [TiktokMetric.cost_per_subscribe]: "currency",
  [TiktokMetric.on_web_subscribe]: "integer",
  [TiktokMetric.cost_per_on_web_subscribe]: "currency",
  [TiktokMetric.skan_subscribe]: "integer",
  [TiktokMetric.cost_per_skan_subscribe]: "currency",
  [TiktokMetric.app_install]: "integer",
  [TiktokMetric.cost_per_app_install]: "currency",
  [TiktokMetric.skan_app_install]: "integer",
  [TiktokMetric.cost_per_skan_app_install]: "currency",
  [TiktokMetric.hook_to_click_ratio]: "percentage",
  [TiktokMetric.total_landing_page_view]: "integer",
  [TiktokMetric.cost_per_total_landing_page_view]: "currency",
  [TiktokMetric.conversion_rate]: "percentage",
  [TiktokMetric.purchase_per_clicks]: "percentage",
  [TiktokMetric.add_to_cart_per_clicks]: "percentage",
  [TiktokMetric.hold_rate]: "percentage",
  [TiktokMetric.stop_scroll_ratio]: "percentage",
  [TiktokMetric.aov]: "currency",
  [TiktokMetric.video_view_rate_p100]: "percentage",
  [TiktokMetric.video_view_rate_p25]: "percentage",
  [TiktokMetric.video_view_rate_p50]: "percentage",
  [TiktokMetric.video_view_rate_p75]: "percentage",
  [TiktokMetric.video_view_rate]: "percentage",
  [TiktokMetric.engagements]: "integer",
  [TiktokMetric.cost_per_engagements]: "currency",
  [TiktokMetric.follows]: "integer",
  [TiktokMetric.cost_per_follows]: "currency",
  [TiktokMetric.likes]: "integer",
  [TiktokMetric.cost_per_likes]: "currency",
  [TiktokMetric.comments]: "integer",
  [TiktokMetric.cost_per_comments]: "currency",
  [TiktokMetric.shares]: "integer",
  [TiktokMetric.cost_per_shares]: "currency",
  [TiktokMetric.profile_visits]: "integer",
  [TiktokMetric.cost_per_profile_visits]: "currency",
  [TiktokMetric.onsite_form]: "integer",
  [TiktokMetric.cost_per_onsite_form]: "currency",
};

export const googleMetricToFormatValueFunction: {
  [key in GoogleMetric]: ValueFormatter;
} = {
  [GoogleMetric.total_cost]: "currency",
  [GoogleMetric.conversions]: "integer",
  [GoogleMetric.conversions_value]: "currency",
  [GoogleMetric.all_conversions]: "integer",
  [GoogleMetric.all_conversions_value]: "currency",
  [GoogleMetric.average_cart_size]: "number",
  [GoogleMetric.average_cpc]: "currency",
  [GoogleMetric.average_cpe]: "currency",
  [GoogleMetric.average_cpv]: "currency",
  [GoogleMetric.average_cpm]: "currency",
  [GoogleMetric.clicks]: "integer",
  [GoogleMetric.ctr]: "percentage",
  [GoogleMetric.roas]: "number",
  [GoogleMetric.engagement_rate]: "percentage",
  [GoogleMetric.engagements]: "integer",
  [GoogleMetric.impressions]: "integer",
  [GoogleMetric.interactions]: "integer",
  [GoogleMetric.interaction_rate]: "percentage",
  [GoogleMetric.new_customer_lifetime_value]: "currency",
  [GoogleMetric.orders]: "integer",
  [GoogleMetric.video_views_p100]: "integer",
  [GoogleMetric.video_views_p25]: "integer",
  [GoogleMetric.video_views_p50]: "integer",
  [GoogleMetric.video_views_p75]: "integer",
  [GoogleMetric.video_view_rate_p100]: "percentage",
  [GoogleMetric.video_view_rate_p25]: "percentage",
  [GoogleMetric.video_view_rate_p50]: "percentage",
  [GoogleMetric.video_view_rate_p75]: "percentage",
  [GoogleMetric.video_view_rate]: "percentage",
  [GoogleMetric.video_views]: "integer",
  [GoogleMetric.view_through_conversions]: "integer",
  [GoogleMetric.value_per_all_conversions]: "currency",
  [GoogleMetric.value_per_conversion]: "currency",
  [GoogleMetric.cost_per_all_conversions]: "currency",
  [GoogleMetric.cost_per_conversion]: "currency",
  [GoogleMetric.conversions_per_clicks]: "percentage",
};

export const metricToPercentageUpColor: {
  [key in MetaMetric | TiktokMetric | GoogleMetric]: "green" | "red";
} = {
  [MetaMetric.purchases]: "green",
  [MetaMetric.purchase_conversion_value]: "green",
  [MetaMetric.reach]: "green",
  [MetaMetric.impressions]: "green",
  [MetaMetric.frequency]: "green",
  [MetaMetric.cpm]: "red",
  [MetaMetric.view_content]: "green",
  [MetaMetric.cost_per_view_content]: "red",
  [MetaMetric.add_to_cart]: "green",
  [MetaMetric.initiate_checkout]: "green",
  [MetaMetric.add_payment_info]: "green",
  [MetaMetric.cpc]: "red",
  [MetaMetric.ctr]: "green",
  [MetaMetric.video_plays]: "green",
  [MetaMetric.video_plays_2sec]: "green",
  [MetaMetric.video_avg_play_time]: "green",
  [MetaMetric.video_views_p25]: "green",
  [MetaMetric.video_views_p50]: "green",
  [MetaMetric.video_views_p75]: "green",
  [MetaMetric.video_views_p100]: "green",
  [MetaMetric.thumb_stop_ratio]: "green",
  [MetaMetric.clicks_per_video_play]: "green",
  [MetaMetric.view_content_per_link_click]: "green",
  [MetaMetric.add_to_cart_per_view_content]: "green",
  [MetaMetric.initiate_checkout_per_add_to_cart]: "green",
  [MetaMetric.add_payment_info_per_add_to_cart]: "green",
  [MetaMetric.purchase_per_add_to_cart]: "green",
  [MetaMetric.add_to_wishlist]: "green",
  [MetaMetric.cost_per_add_to_wishlist]: "red",
  [MetaMetric.subscribe]: "green",
  [MetaMetric.cost_per_subscribe]: "red",
  [MetaMetric.search]: "green",
  [MetaMetric.cost_per_search]: "red",
  [MetaMetric.app_install]: "green",
  [MetaMetric.cost_per_app_install]: "red",
  [MetaMetric.contact]: "green",
  [MetaMetric.cost_per_contact]: "red",
  [MetaMetric.complete_registration]: "green",
  [MetaMetric.cost_per_complete_registration]: "red",
  [MetaMetric.landing_page_view]: "green",
  [MetaMetric.cost_per_landing_page_view]: "red",
  [MetaMetric.conversion_rate]: "green",
  [MetaMetric.purchase_per_clicks]: "green",
  [MetaMetric.add_to_cart_per_clicks]: "green",
  [MetaMetric.stop_scroll_ratio]: "green",
  [MetaMetric.aov]: "green",
  [MetaMetric.spend]: "green",
  [MetaMetric.cost_per_purchase]: "red",
  [MetaMetric.purchase_roas]: "green",
  [MetaMetric.cost_per_add_to_cart]: "red",
  [MetaMetric.cost_per_initiate_checkout]: "red",
  [MetaMetric.cost_per_add_payment_info]: "red",
  [MetaMetric.video_plays_3sec]: "green",
  [MetaMetric.datads_thumb_stop_ratio]: "green",
  [MetaMetric.customize_product]: "green",
  [MetaMetric.cost_per_customize_product]: "red",
  [MetaMetric.donate]: "green",
  [MetaMetric.cost_per_donate]: "red",
  [MetaMetric.find_location]: "green",
  [MetaMetric.cost_per_find_location]: "red",
  [MetaMetric.lead]: "green",
  [MetaMetric.cost_per_lead]: "red",
  [MetaMetric.schedule]: "green",
  [MetaMetric.cost_per_schedule]: "red",
  [MetaMetric.start_trial]: "green",
  [MetaMetric.cost_per_start_trial]: "red",
  [MetaMetric.submit_application]: "green",
  [MetaMetric.cost_per_submit_application]: "red",
  [MetaMetric.hold_rate]: "green",
  [MetaMetric.hook_to_click_ratio]: "green",
  [MetaMetric.ctr_all]: "green",
  [MetaMetric.thruplays]: "green",
  [MetaMetric.outbound_clicks]: "green",
  [MetaMetric.outbound_clicks_cpc]: "green",
  [MetaMetric.outbound_clicks_ctr]: "green",
  [MetaMetric.unique_outbound_clicks]: "green",
  [MetaMetric.unique_outbound_clicks_ctr]: "green",
  [MetaMetric.unique_outbound_clicks_cpc]: "green",
  [MetaMetric.video_view_rate]: "green",
  [MetaMetric.post_comment]: "green",
  [MetaMetric.cost_per_post_comment]: "red",
  [MetaMetric.post_save]: "green",
  [MetaMetric.cost_per_post_save]: "red",
  [MetaMetric.post_share]: "green",
  [MetaMetric.cost_per_post_share]: "red",
  [MetaMetric.post_reaction]: "green",
  [MetaMetric.cost_per_post_reaction]: "red",
  [MetaMetric.post_engagement]: "green",
  [MetaMetric.cost_per_post_engagement]: "red",
  [MetaMetric.page_engagement]: "green",
  [MetaMetric.cost_per_page_engagement]: "red",
  [MetaMetric.page_like]: "green",
  [MetaMetric.cost_per_page_like]: "red",
  [MetaMetric.page_photo_view]: "green",
  [MetaMetric.cost_per_page_photo_view]: "red",
  [TiktokMetric.cost_per_total_landing_page_view]: "red",
  [TiktokMetric.total_landing_page_view]: "green",
  [TiktokMetric.complete_payment]: "green",
  [TiktokMetric.skan_purchase]: "green",
  [TiktokMetric.purchase]: "green",
  [TiktokMetric.cost_per_complete_payment]: "red",
  [TiktokMetric.complete_payment_roas]: "green",
  [TiktokMetric.total_complete_payment_rate]: "green",
  [TiktokMetric.product_details_page_browse]: "green",
  [TiktokMetric.skan_view_content]: "green",
  [TiktokMetric.cost_per_product_details_page_browse]: "red",
  [TiktokMetric.skan_cost_per_view_content]: "red",
  [TiktokMetric.app_event_add_to_cart]: "green",
  [TiktokMetric.web_event_add_to_cart]: "green",
  [TiktokMetric.skan_add_to_cart]: "green",
  [TiktokMetric.cost_per_app_event_add_to_cart]: "red",
  [TiktokMetric.cost_per_web_event_add_to_cart]: "red",
  [TiktokMetric.skan_cost_per_add_to_cart]: "red",
  [TiktokMetric.checkout]: "green",
  [TiktokMetric.skan_checkout]: "green",
  [TiktokMetric.cost_per_checkout]: "red",
  [TiktokMetric.skan_cost_per_checkout]: "red",
  [TiktokMetric.add_billing]: "green",
  [TiktokMetric.skan_add_payment_info]: "green",
  [TiktokMetric.cost_per_add_billing]: "red",
  [TiktokMetric.skan_cost_per_add_payment_info]: "red",
  [TiktokMetric.video_play_actions]: "green",
  [TiktokMetric.video_watched_2s]: "green",
  [TiktokMetric.video_watched_6s]: "green",
  [TiktokMetric.average_video_play]: "green",
  [TiktokMetric.average_video_play_per_user]: "green",
  [TiktokMetric.on_web_add_to_wishlist]: "green",
  [TiktokMetric.cost_per_on_web_add_to_wishlist]: "red",
  [TiktokMetric.skan_add_to_wishlist]: "green",
  [TiktokMetric.cost_per_skan_add_to_wishlist]: "red",
  [TiktokMetric.button_click]: "green",
  [TiktokMetric.cost_per_button_click]: "red",
  [TiktokMetric.registration]: "green",
  [TiktokMetric.cost_per_registration]: "red",
  [TiktokMetric.user_registration]: "green",
  [TiktokMetric.cost_per_user_registration]: "red",
  [TiktokMetric.skan_registration]: "green",
  [TiktokMetric.cost_per_skan_registration]: "red",
  [TiktokMetric.online_consult]: "green",
  [TiktokMetric.cost_per_online_consult]: "red",
  [TiktokMetric.download_start]: "green",
  [TiktokMetric.cost_per_download_start]: "red",
  [TiktokMetric.on_web_order]: "green",
  [TiktokMetric.cost_per_on_web_order]: "red",
  [TiktokMetric.page_event_search]: "green",
  [TiktokMetric.cost_per_page_event_search]: "red",
  [TiktokMetric.skan_search]: "green",
  [TiktokMetric.cost_per_skan_search]: "red",
  [TiktokMetric.form]: "green",
  [TiktokMetric.cost_per_form]: "red",
  [TiktokMetric.on_web_subscribe]: "green",
  [TiktokMetric.cost_per_on_web_subscribe]: "red",
  [TiktokMetric.skan_subscribe]: "green",
  [TiktokMetric.cost_per_skan_subscribe]: "red",
  [TiktokMetric.skan_app_install]: "green",
  [TiktokMetric.cost_per_skan_app_install]: "red",
  [TiktokMetric.engagements]: "green",
  [TiktokMetric.cost_per_engagements]: "red",
  [TiktokMetric.follows]: "green",
  [TiktokMetric.cost_per_follows]: "red",
  [TiktokMetric.likes]: "green",
  [TiktokMetric.cost_per_likes]: "red",
  [TiktokMetric.comments]: "green",
  [TiktokMetric.cost_per_comments]: "red",
  [TiktokMetric.shares]: "green",
  [TiktokMetric.cost_per_shares]: "red",
  [TiktokMetric.profile_visits]: "green",
  [TiktokMetric.cost_per_profile_visits]: "red",
  [TiktokMetric.onsite_form]: "green",
  [TiktokMetric.cost_per_onsite_form]: "red",
  [GoogleMetric.conversions]: "green",
  [GoogleMetric.conversions_value]: "green",
  [GoogleMetric.all_conversions]: "green",
  [GoogleMetric.all_conversions_value]: "green",
  [GoogleMetric.average_cart_size]: "green",
  [GoogleMetric.average_cpc]: "red",
  [GoogleMetric.average_cpe]: "red",
  [GoogleMetric.average_cpv]: "red",
  [GoogleMetric.average_cpm]: "red",
  [GoogleMetric.clicks]: "green",
  [GoogleMetric.engagement_rate]: "green",
  [GoogleMetric.interactions]: "green",
  [GoogleMetric.interaction_rate]: "green",
  [GoogleMetric.new_customer_lifetime_value]: "green",
  [GoogleMetric.orders]: "green",
  [GoogleMetric.video_views]: "green",
  [GoogleMetric.view_through_conversions]: "green",
  [GoogleMetric.value_per_all_conversions]: "green",
  [GoogleMetric.value_per_conversion]: "green",
  [GoogleMetric.cost_per_all_conversions]: "red",
  [GoogleMetric.cost_per_conversion]: "red",
  [GoogleMetric.video_view_rate_p100]: "green",
  [GoogleMetric.video_view_rate_p25]: "green",
  [GoogleMetric.video_view_rate_p50]: "green",
  [GoogleMetric.video_view_rate_p75]: "green",
  [GoogleMetric.total_cost]: "green",
  [GoogleMetric.roas]: "green",
  [GoogleMetric.conversions_per_clicks]: "green",
};

export const providerToSpend: Record<Provider, string> = {
  [Provider.META]: MetaMetric.spend,
  [Provider.TIKTOK]: TiktokMetric.spend,
  [Provider.GOOGLE]: GoogleMetric.total_cost,
  [Provider.ADTRIBUTE]: MetaMetric.spend,
  [Provider.GOOGLE_SHEETS]: MetaMetric.spend,
  [Provider.HYROS]: MetaMetric.spend,
  [Provider.TRIPLEWHALE]: MetaMetric.spend,
  [Provider.TRACIFY]: MetaMetric.spend,
};

export const tiktokMetricToAggregateFunction: {
  [key in TiktokMetric]: AggregateFunction;
} = {
  [TiktokMetric.spend]: AggregateFunction.SUM,
  [TiktokMetric.reach]: AggregateFunction.SUM,
  [TiktokMetric.impressions]: AggregateFunction.SUM,
  [TiktokMetric.frequency]: AggregateFunction.WEIGHTED_AVG,
  [TiktokMetric.cpm]: AggregateFunction.WEIGHTED_AVG,
  [TiktokMetric.clicks]: AggregateFunction.SUM,
  [TiktokMetric.cpc]: AggregateFunction.WEIGHTED_AVG,
  [TiktokMetric.ctr]: AggregateFunction.WEIGHTED_AVG,
  [TiktokMetric.complete_payment]: AggregateFunction.SUM,
  [TiktokMetric.skan_purchase]: AggregateFunction.SUM,
  [TiktokMetric.purchase]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_complete_payment]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.complete_payment_roas]: AggregateFunction.WEIGHTED_AVG,
  [TiktokMetric.total_complete_payment_rate]: AggregateFunction.SUM,
  [TiktokMetric.view_content]: AggregateFunction.SUM,
  [TiktokMetric.product_details_page_browse]: AggregateFunction.SUM,
  [TiktokMetric.skan_view_content]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_product_details_page_browse]:
    AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.cost_per_view_content]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_cost_per_view_content]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.app_event_add_to_cart]: AggregateFunction.SUM,
  [TiktokMetric.web_event_add_to_cart]: AggregateFunction.SUM,
  [TiktokMetric.skan_add_to_cart]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_app_event_add_to_cart]:
    AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.cost_per_web_event_add_to_cart]:
    AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_cost_per_add_to_cart]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.initiate_checkout]: AggregateFunction.SUM,
  [TiktokMetric.checkout]: AggregateFunction.SUM,
  [TiktokMetric.skan_checkout]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_initiate_checkout]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.cost_per_checkout]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_cost_per_checkout]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.add_payment_info]: AggregateFunction.SUM,
  [TiktokMetric.add_billing]: AggregateFunction.SUM,
  [TiktokMetric.skan_add_payment_info]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_add_payment_info]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.cost_per_add_billing]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_cost_per_add_payment_info]:
    AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.video_play_actions]: AggregateFunction.SUM,
  [TiktokMetric.video_watched_2s]: AggregateFunction.SUM,
  [TiktokMetric.video_watched_6s]: AggregateFunction.SUM,
  [TiktokMetric.average_video_play]: AggregateFunction.AVG,
  [TiktokMetric.average_video_play_per_user]: AggregateFunction.WEIGHTED_AVG,
  [TiktokMetric.video_views_p25]: AggregateFunction.SUM,
  [TiktokMetric.video_views_p50]: AggregateFunction.SUM,
  [TiktokMetric.video_views_p75]: AggregateFunction.SUM,
  [TiktokMetric.video_views_p100]: AggregateFunction.SUM,
  [TiktokMetric.datads_thumb_stop_ratio]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.clicks_per_video_play]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.view_content_per_link_click]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.add_to_cart_per_view_content]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.initiate_checkout_per_add_to_cart]:
    AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.add_payment_info_per_add_to_cart]:
    AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.purchase_per_add_to_cart]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.add_to_wishlist]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_add_to_wishlist]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.on_web_add_to_wishlist]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_on_web_add_to_wishlist]:
    AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_add_to_wishlist]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_skan_add_to_wishlist]:
    AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.button_click]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_button_click]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.registration]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_registration]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.user_registration]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_user_registration]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_registration]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_skan_registration]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.online_consult]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_online_consult]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.download_start]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_download_start]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.on_web_order]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_on_web_order]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.search]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_search]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.page_event_search]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_page_event_search]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_search]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_skan_search]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.form]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_form]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.subscribe]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_subscribe]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.on_web_subscribe]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_on_web_subscribe]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_subscribe]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_skan_subscribe]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.app_install]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_app_install]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.skan_app_install]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_skan_app_install]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.hook_to_click_ratio]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.total_landing_page_view]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_total_landing_page_view]:
    AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.conversion_rate]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.purchase_per_clicks]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.add_to_cart_per_clicks]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.hold_rate]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.stop_scroll_ratio]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.aov]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.video_view_rate_p100]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.video_view_rate_p25]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.video_view_rate_p50]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.video_view_rate_p75]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.video_view_rate]: AggregateFunction.CUSTOM_RATIO,
  [TiktokMetric.engagements]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_engagements]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.follows]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_follows]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.likes]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_likes]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.comments]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_comments]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.shares]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_shares]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.profile_visits]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_profile_visits]: AggregateFunction.COST_PER_ACTION,
  [TiktokMetric.onsite_form]: AggregateFunction.SUM,
  [TiktokMetric.cost_per_onsite_form]: AggregateFunction.COST_PER_ACTION,
};

export const googleMetricToAggregateFunction: {
  [key in GoogleMetric]: AggregateFunction;
} = {
  [GoogleMetric.total_cost]: AggregateFunction.SUM,
  [GoogleMetric.conversions]: AggregateFunction.SUM,
  [GoogleMetric.conversions_value]: AggregateFunction.SUM,
  [GoogleMetric.all_conversions]: AggregateFunction.SUM,
  [GoogleMetric.all_conversions_value]: AggregateFunction.SUM,
  [GoogleMetric.average_cart_size]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.average_cpc]: AggregateFunction.WEIGHTED_AVG,
  [GoogleMetric.average_cpe]: AggregateFunction.WEIGHTED_AVG,
  [GoogleMetric.average_cpv]: AggregateFunction.WEIGHTED_AVG,
  [GoogleMetric.average_cpm]: AggregateFunction.WEIGHTED_AVG,
  [GoogleMetric.clicks]: AggregateFunction.SUM,
  [GoogleMetric.ctr]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.engagement_rate]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.engagements]: AggregateFunction.SUM,
  [GoogleMetric.impressions]: AggregateFunction.SUM,
  [GoogleMetric.interactions]: AggregateFunction.SUM,
  [GoogleMetric.interaction_rate]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.new_customer_lifetime_value]: AggregateFunction.SUM,
  [GoogleMetric.orders]: AggregateFunction.SUM,
  [GoogleMetric.video_views_p100]: AggregateFunction.SUM,
  [GoogleMetric.video_views_p25]: AggregateFunction.SUM,
  [GoogleMetric.video_views_p50]: AggregateFunction.SUM,
  [GoogleMetric.video_views_p75]: AggregateFunction.SUM,
  [GoogleMetric.video_view_rate_p100]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.video_view_rate_p25]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.video_view_rate_p50]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.video_view_rate_p75]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.video_view_rate]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.video_views]: AggregateFunction.SUM,
  [GoogleMetric.view_through_conversions]: AggregateFunction.SUM,
  [GoogleMetric.value_per_all_conversions]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.value_per_conversion]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.cost_per_all_conversions]: AggregateFunction.COST_PER_ACTION,
  [GoogleMetric.cost_per_conversion]: AggregateFunction.COST_PER_ACTION,
  [GoogleMetric.roas]: AggregateFunction.CUSTOM_RATIO,
  [GoogleMetric.conversions_per_clicks]: AggregateFunction.CUSTOM_RATIO,
};

export const metaMetricToAggregateFunction: {
  [key in MetaMetric]: AggregateFunction;
} = {
  [MetaMetric.spend]: AggregateFunction.SUM,
  [MetaMetric.reach]: AggregateFunction.SUM,
  [MetaMetric.impressions]: AggregateFunction.SUM,
  [MetaMetric.frequency]: AggregateFunction.WEIGHTED_AVG,
  [MetaMetric.cpm]: AggregateFunction.WEIGHTED_AVG,
  [MetaMetric.clicks]: AggregateFunction.SUM,
  [MetaMetric.cpc]: AggregateFunction.WEIGHTED_AVG,
  [MetaMetric.ctr]: AggregateFunction.WEIGHTED_AVG,
  [MetaMetric.ctr_all]: AggregateFunction.WEIGHTED_AVG,
  [MetaMetric.purchases]: AggregateFunction.SUM,
  [MetaMetric.cost_per_purchase]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.purchase_roas]: AggregateFunction.WEIGHTED_AVG,
  [MetaMetric.purchase_conversion_value]: AggregateFunction.SUM,
  [MetaMetric.view_content]: AggregateFunction.SUM,
  [MetaMetric.cost_per_view_content]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.add_to_cart]: AggregateFunction.SUM,
  [MetaMetric.cost_per_add_to_cart]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.initiate_checkout]: AggregateFunction.SUM,
  [MetaMetric.cost_per_initiate_checkout]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.add_payment_info]: AggregateFunction.SUM,
  [MetaMetric.cost_per_add_payment_info]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.video_plays]: AggregateFunction.SUM,
  [MetaMetric.video_plays_2sec]: AggregateFunction.SUM,
  [MetaMetric.video_plays_3sec]: AggregateFunction.SUM,
  [MetaMetric.video_avg_play_time]: AggregateFunction.AVG,
  [MetaMetric.video_views_p25]: AggregateFunction.SUM,
  [MetaMetric.video_views_p50]: AggregateFunction.SUM,
  [MetaMetric.video_views_p75]: AggregateFunction.SUM,
  [MetaMetric.video_views_p100]: AggregateFunction.SUM,
  [MetaMetric.thumb_stop_ratio]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.datads_thumb_stop_ratio]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.clicks_per_video_play]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.view_content_per_link_click]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.add_to_cart_per_view_content]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.initiate_checkout_per_add_to_cart]:
    AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.add_payment_info_per_add_to_cart]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.purchase_per_add_to_cart]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.app_install]: AggregateFunction.SUM,
  [MetaMetric.cost_per_app_install]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.complete_registration]: AggregateFunction.SUM,
  [MetaMetric.cost_per_complete_registration]:
    AggregateFunction.COST_PER_ACTION,
  [MetaMetric.contact]: AggregateFunction.SUM,
  [MetaMetric.cost_per_contact]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.customize_product]: AggregateFunction.SUM,
  [MetaMetric.cost_per_customize_product]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.donate]: AggregateFunction.SUM,
  [MetaMetric.cost_per_donate]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.find_location]: AggregateFunction.SUM,
  [MetaMetric.cost_per_find_location]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.lead]: AggregateFunction.SUM,
  [MetaMetric.cost_per_lead]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.schedule]: AggregateFunction.SUM,
  [MetaMetric.cost_per_schedule]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.search]: AggregateFunction.SUM,
  [MetaMetric.cost_per_search]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.start_trial]: AggregateFunction.SUM,
  [MetaMetric.cost_per_start_trial]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.submit_application]: AggregateFunction.SUM,
  [MetaMetric.cost_per_submit_application]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.subscribe]: AggregateFunction.SUM,
  [MetaMetric.cost_per_subscribe]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.add_to_wishlist]: AggregateFunction.SUM,
  [MetaMetric.cost_per_add_to_wishlist]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.hook_to_click_ratio]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.landing_page_view]: AggregateFunction.SUM,
  [MetaMetric.cost_per_landing_page_view]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.conversion_rate]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.purchase_per_clicks]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.add_to_cart_per_clicks]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.thruplays]: AggregateFunction.SUM,
  [MetaMetric.hold_rate]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.stop_scroll_ratio]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.aov]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.outbound_clicks]: AggregateFunction.SUM,
  [MetaMetric.outbound_clicks_cpc]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.outbound_clicks_ctr]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.unique_outbound_clicks]: AggregateFunction.SUM,
  [MetaMetric.unique_outbound_clicks_ctr]: AggregateFunction.WEIGHTED_AVG,
  [MetaMetric.unique_outbound_clicks_cpc]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.video_view_rate_p100]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.video_view_rate_p25]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.video_view_rate_p50]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.video_view_rate_p75]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.video_view_rate]: AggregateFunction.CUSTOM_RATIO,
  [MetaMetric.post_comment]: AggregateFunction.SUM,
  [MetaMetric.cost_per_post_comment]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.post_save]: AggregateFunction.SUM,
  [MetaMetric.cost_per_post_save]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.post_share]: AggregateFunction.SUM,
  [MetaMetric.cost_per_post_share]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.post_reaction]: AggregateFunction.SUM,
  [MetaMetric.cost_per_post_reaction]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.post_engagement]: AggregateFunction.SUM,
  [MetaMetric.cost_per_post_engagement]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.page_engagement]: AggregateFunction.SUM,
  [MetaMetric.cost_per_page_engagement]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.page_like]: AggregateFunction.SUM,
  [MetaMetric.cost_per_page_like]: AggregateFunction.COST_PER_ACTION,
  [MetaMetric.page_photo_view]: AggregateFunction.SUM,
  [MetaMetric.cost_per_page_photo_view]: AggregateFunction.COST_PER_ACTION,
};

export const providerToFallbackPrimaryMetric: Record<Provider, AdsMetric> = {
  [Provider.META]: MetaMetric.purchases,
  [Provider.TIKTOK]: TiktokMetric.complete_payment,
  [Provider.GOOGLE]: GoogleMetric.conversions,
  [Provider.ADTRIBUTE]: MetaMetric.spend,
  [Provider.GOOGLE_SHEETS]: MetaMetric.spend,
  [Provider.HYROS]: MetaMetric.spend,
  [Provider.TRIPLEWHALE]: MetaMetric.spend,
  [Provider.TRACIFY]: MetaMetric.spend,
};

export const providerToFallbackSecondaryMetrics: Record<Provider, AdsMetric[]> =
  {
    [Provider.META]: [
      MetaMetric.spend,
      MetaMetric.cost_per_purchase,
      MetaMetric.thumb_stop_ratio,
      MetaMetric.hold_rate,
      MetaMetric.ctr,
    ],
    [Provider.TIKTOK]: [
      TiktokMetric.spend,
      TiktokMetric.cost_per_complete_payment,
      TiktokMetric.datads_thumb_stop_ratio,
      TiktokMetric.hold_rate,
      TiktokMetric.ctr,
    ],
    [Provider.GOOGLE]: [
      GoogleMetric.total_cost,
      GoogleMetric.cost_per_conversion,
      GoogleMetric.engagement_rate,
      GoogleMetric.video_view_rate,
      GoogleMetric.ctr,
    ],
    [Provider.ADTRIBUTE]: [MetaMetric.spend],
    [Provider.GOOGLE_SHEETS]: [MetaMetric.spend],
    [Provider.HYROS]: [MetaMetric.spend],
    [Provider.TRIPLEWHALE]: [MetaMetric.spend],
    [Provider.TRACIFY]: [MetaMetric.spend],
  };
