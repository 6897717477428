import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import { MetricsFilter } from "~/types/Metrics";
import { Provider } from "~/types/shared";

dayjs.extend(utc); // eslint-disable-line import/no-named-as-default-member
dayjs.extend(timezone); // eslint-disable-line import/no-named-as-default-member

export function wait(ms = 2000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, ms);
  });
}

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function getNameLetters(txt: string | undefined | null) {
  if (typeof txt !== "string" || txt.length <= 0) return "";
  return txt.slice(0, 2).toUpperCase();
}

export const sortObjectNumbers =
  <T extends { [key: string]: any }>(order: number, key: string) =>
  (a: T, b: T) => {
    return order === 1 ? b[key] - a[key] : a[key] - b[key];
  };

export const sortNumbers = (order: number) => (a: number, b: number) => {
  return order === 1 ? b - a : a - b;
};

export const sortTimeString = (order: number) => (a: Date, b: Date) => {
  return order === 1
    ? new Date(b).getTime() - new Date(a).getTime()
    : new Date(a).getTime() - new Date(b).getTime();
};

export const sortObjectTimeString =
  <T extends { [key: string]: any }>(order: number, key: string) =>
  (a: T, b: T) => {
    return order === 1
      ? new Date(b[key]).getTime() - new Date(a[key]).getTime()
      : new Date(a[key]).getTime() - new Date(b[key]).getTime();
  };

export const sortDates = (order: number) => (a: Date, b: Date) => {
  return order === 1 ? b.getTime() - a.getTime() : a.getTime() - b.getTime();
};

export const sortObjectDates =
  <T extends { [key: string]: any }>(order: number, key: string) =>
  (a: T, b: T) => {
    return order === 1
      ? b[key].getTime() - a[key].getTime()
      : a[key].getTime() - b[key].getTime();
  };

export const sortStrings = (order: number) => (a: string, b: string) => {
  return order === 1 ? sortStringsDescending(a, b) : sortStringsAscending(a, b);
};

export const sortObjectStrings =
  <T extends { [key: string]: any }>(order: number, key: string) =>
  (a: T, b: T) => {
    return order === 1
      ? sortStringsDescending(a[key], b[key])
      : sortStringsAscending(a[key], b[key]);
  };

export function sortStringsAscending(a: string, b: string): number {
  if (b < a) return 1;
  if (b > a) return -1;
  return 0;
}

export function sortStringsDescending(a: string, b: string): number {
  if (b < a) return -1;
  if (b > a) return 1;
  return 0;
}

export function capitalizeFirstLetter(txt: string | undefined | null) {
  if (typeof txt !== "string" || txt.length <= 0) return "";
  return txt.charAt(0).toUpperCase() + txt.toLowerCase().slice(1);
}

export function formatNumber(num: number) {
  return new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
}

export function formatInteger(num: number) {
  return new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
}

export function formatPercentage(num: number, digits = 2) {
  return new Intl.NumberFormat("en-GB", {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    useGrouping: false, // This disables the commas
  })
    .format(num)
    .replace("-0", "0");
}

export function formatFractionalPercentage(num: number, digits = 2) {
  return formatPercentage(num / 100, digits);
}

export const getFormatCurrency = (currency: string) => (num: number) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

export function formatCurrency(
  num: number,
  options?: { currency?: string; compact?: boolean } | undefined,
) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: typeof options?.currency === "string" ? options.currency : "EUR",
    notation: options?.compact ? "compact" : undefined,
    compactDisplay: options?.compact ? "short" : undefined,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
}

export function formatSeconds(num: number) {
  return `${formatNumber(num)} sec`;
}

export function copyTextToClipboard(text: string) {
  if (!process.client || !document || !navigator) return;
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
}

export function fallbackCopyTextToClipboard(text: string) {
  if (!process.client || !document) return;

  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {}

  document.body.removeChild(textArea);
}

export function toUtcDate(date: Date) {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function toLocalDate(date: Date) {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
  return result;
}

export function extractNumber(num: string) {
  return Number(num.replace(/[^0-9.-]+/g, ""));
}

export function groupBy<T extends { [key: string]: any }>(
  arr: T[],
  key: string,
): { [key: string]: T[] } {
  const byString = function (o: any, s: string) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  };

  return arr.reduce((r, a) => {
    const ikey = byString(a, key);
    r[ikey] = r[ikey] || [];
    r[ikey].push(a);
    return r;
  }, Object.create(null));
}

export function ungroup<T extends { [key: string]: any }>(obj: {
  [key: string]: T[];
}) {
  const arr: T[] = [];
  Object.keys(obj).forEach((k) => {
    arr.push(...obj[k]);
  });
  return arr;
}

export function getDateDaysBefore(
  now: Date,
  daysBefore: number,
  splitAtHours = true,
) {
  const dateDaysBefore = new Date(
    now.getTime() - daysBefore * 24 * 60 * 60 * 1000,
  );
  return splitAtHours ? formatDateToZeroHours(dateDaysBefore) : dateDaysBefore;
}

export function getStartOfWeek(
  now: Date,
  weeksBefore: number,
  splitAtHours = true,
) {
  const beforeXWeeks = new Date(
    now.getTime() - weeksBefore * 7 * 24 * 60 * 60 * 1000,
  );
  const day = beforeXWeeks.getDay();
  const diffToMonday = beforeXWeeks.getDate() - day + (day === 0 ? -6 : 1);
  const lastMonday = new Date(beforeXWeeks.setDate(diffToMonday));
  return splitAtHours ? formatDateToZeroHours(lastMonday) : lastMonday;
}

export function getEndOfWeek(
  now: Date,
  weeksBefore: number,
  splitAtHours = true,
) {
  const beforeXWeeks = new Date(
    now.getTime() - weeksBefore * 7 * 24 * 60 * 60 * 1000,
  );
  const beforeXWeeks2 = new Date(beforeXWeeks);
  const day = beforeXWeeks.getDay();
  const diffToMonday = beforeXWeeks.getDate() - day + (day === 0 ? -6 : 1);
  const lastSunday = new Date(beforeXWeeks2.setDate(diffToMonday + 6));
  return splitAtHours ? formatDateToZeroHours(lastSunday) : lastSunday;
}

export function getEndOfMonth(
  now: Date,
  monthsBefore: number,
  splitAtHours = true,
) {
  const d = new Date(now);
  d.setMonth(d.getMonth() - monthsBefore + 1);
  d.setDate(0);
  const lastDayXMonthsAgo = d;
  return splitAtHours
    ? formatDateToZeroHours(lastDayXMonthsAgo)
    : lastDayXMonthsAgo;
}

export function getStartOfMonth(
  now: Date,
  monthsBefore: number,
  splitAtHours = true,
) {
  const d = new Date(now);
  d.setMonth(d.getMonth() - monthsBefore);
  d.setDate(1);
  const firstDayXMonthsAgo = d;
  return splitAtHours
    ? formatDateToZeroHours(firstDayXMonthsAgo)
    : firstDayXMonthsAgo;
}

export function formatDateToZeroHours(date: Date) {
  const splitAtHours = date.toISOString().split("T")[0]; // Date in format YYYY-MM-DD
  return new Date(splitAtHours);
}

export function daysBetween(
  startDate: Date,
  endDate: Date,
  roundToInt = false,
) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const partial =
    (treatAsUTC(endDate).getTime() - treatAsUTC(startDate).getTime()) /
    millisecondsPerDay;
  return roundToInt ? Math.round(partial) : partial;
}

export function hoursBetween(startDate: Date, endDate: Date) {
  const millisecondsPerHour = 60 * 60 * 1000;
  return (
    (treatAsUTC(endDate).getTime() - treatAsUTC(startDate).getTime()) /
    millisecondsPerHour
  );
}

export function treatAsUTC(date: Date) {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function ensureUniqueMaintainOrder<T>(arr: Array<T>) {
  const res: Array<T> = [];

  for (const el of arr) {
    if (!res.includes(el)) {
      res.push(el);
    }
  }

  return res;
}

export function sortAscending(a: number, b: number) {
  return a - b;
}

export function sortDescending(a: number, b: number) {
  return b - a;
}

export function everyNth<T>(arr: Array<T>, n: number) {
  const everyNthArr: Array<T> = [];

  for (let i = 0; i < arr.length; i = i + n) {
    everyNthArr.push(arr[i]);
  }

  return everyNthArr;
}

export async function urlToFile(url: string, opts?: RequestInit) {
  if (!process.client) return null;
  try {
    const response = await fetch(url, opts);
    const blob = await response.blob();
    const file = new File([blob], "url.jpg", { type: blob.type });
    return file;
  } catch (e) {
    return null;
  }
}

export function createAvatar(seed: string, style: string) {
  return `https://api.dicebear.com/6.x/${style}/svg?seed=${seed}&backgroundColor=6493F2`;
}

export function arraysEqual<T>(
  a: Array<T> | undefined | null,
  b: Array<T> | undefined | null,
  elementOrderMatter = true,
) {
  if (a == null || b == null) return false;

  const _a = [...a];
  const _b = [...b];

  if (_a.length !== _b.length) return false;

  if (!elementOrderMatter) {
    // Elements order should not matter --> Sort
    _a.sort();
    _b.sort();
  }

  for (let i = 0; i < _a.length; ++i) {
    if (_a[i] !== _b[i]) return false;
  }
  return true;
}

export function parseUtc(dStr: string) {
  const parts = dStr.split("T");
  const [year, month, day] = parts[0].split("-");
  const [hour, minute, _second] = parts[1].split(":");
  const second = _second.split(".")[0];
  return {
    year: Number(year),
    month: Number(month),
    day: Number(day),
    hour: Number(hour),
    minute: Number(minute),
    second: Number(second),
  };
}

export function textToHtml(text: string) {
  const matchLinksRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

  const textWithLinks = text.replace(
    matchLinksRegex,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
  );

  return '<p class="whitespace-pre-wrap">' + textWithLinks + "</p>";
}

export function getFirstTwoLetters(str: string) {
  const words = str.split(" ");
  if (words.length === 1) {
    return words[0].slice(0, 2).toUpperCase();
  } else {
    return (
      words[0].slice(0, 1).toUpperCase() + words[1].slice(0, 1).toUpperCase()
    );
  }
}

export function arrToBatches<T>(arr: T[], batchSize: number) {
  return arr.reduce((resultArray, item, index) => {
    const batchIdx = Math.floor(index / batchSize);

    if (!Array.isArray(resultArray[batchIdx])) {
      resultArray[batchIdx] = []; // start a new batch
    }

    resultArray[batchIdx].push(item);

    return resultArray;
  }, [] as T[][]);
}

export function getBatchStartEnd(input: {
  pageNumber: number;
  pageSize: number;
  total?: number;
}) {
  const start = (input.pageNumber - 1) * input.pageSize;
  const end = start + input.pageSize;
  return { start, end: input.total ? Math.min(end, input.total) : end };
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function dateStrToDate(dateStr: string) {
  return dayjs(dateStr).toDate();
}

export function dateToShortString(date: Date | string) {
  return dayjs(date).format("MMM DD, YY");
}

export function getAsDayJs(date: Date | string) {
  return dayjs(date);
}

export function getDayJs() {
  return dayjs();
}

export function filterEqual(
  filter1: MetricsFilter[][] | null | undefined,
  filter2: MetricsFilter[][] | null | undefined,
) {
  if (filter1 == null || filter2 == null) return false;
  // Filter looks like [[{key:{key:value}}]]
  if (filter1.length !== filter2.length) return false;
  for (let i = 0; i < filter1.length; i++) {
    if (filter1[i].length !== filter2[i].length) return false;
    for (let j = 0; j < filter1[i].length; j++) {
      if (JSON.stringify(filter1[i][j]) !== JSON.stringify(filter2[i][j]))
        return false;
    }
  }
  return true;
}

export function round(num: number, digits = 2) {
  return Number(num.toFixed(digits));
}

export function getProviderPrefix(metricWithPrefix: string): Provider {
  return metricWithPrefix.split(":")[0] as Provider;
}

export function prefixProvider(provider: Provider, metric: string) {
  return provider + ":" + metric;
}

export function removeProviderPrefix(metric: string) {
  if (metric == null) return metric;
  const parts = metric.split(":");
  if (parts.length <= 1) return metric;
  return parts[1];
}

export function dateKeyToDateStr(key: {
  year: number;
  month: number;
  day: number;
}) {
  const month = key.month.toString().padStart(2, "0");
  const day = key.day.toString().padStart(2, "0");
  return `${key.year}-${month}-${day}`;
}
