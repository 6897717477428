import LogRocket from "logrocket";
import { Intercom } from "@intercom/messenger-js-sdk";
import { useAppUserStore } from "~/stores/appUser";
import { daysBetween } from "~/shared/utils";

export default defineNuxtPlugin((_) => {
  const { appUserMe } = useAppUserStore();
  if (!appUserMe) {
    return;
  }
  const { getAnalyticsUser, getAnalyticsWorkspace, identify, group } =
    useSegment();
  const { userId, traits: userTraits } = getAnalyticsUser(appUserMe);
  identify(userId, userTraits);
  const { getWorkspaceById } = useWorkspaceStore();
  const workspace = getWorkspaceById(appUserMe.activeWorkspace);
  if (!workspace) {
    return;
  }
  const { groupId, traits: workspaceTraits } = getAnalyticsWorkspace(
    appUserMe.id,
    workspace,
  );
  group(groupId, workspaceTraits);
  const {
    public: { isProduction, intercomAppId, logrocketAppId },
  } = useRuntimeConfig();
  if (isProduction && intercomAppId) {
    Intercom({
      app_id: intercomAppId,
      name: appUserMe.displayName,
      email: appUserMe.email,
      created_at: Math.floor(new Date(appUserMe.createdAt).getTime() / 1000),
      user_hash: appUserMe.intercomUserHash,
    });
  }
  const router = useRouter();
  const isOnboarding = router.currentRoute.value.path.includes("/onboarding");
  const isConnect = router.currentRoute.value.path.includes("/connect");
  const userCreatedRecently =
    daysBetween(userTraits.createdAt, new Date()) < 14;
  if (
    (isOnboarding || isConnect || userCreatedRecently) &&
    logrocketAppId &&
    isProduction &&
    !appUserMe.internal
  ) {
    LogRocket.init(logrocketAppId);
    LogRocket.identify(userId, {
      ...userTraits,
      createdAt: userTraits.createdAt.toDateString(),
    });
  }
});
