/* eslint-disable no-console */
import * as Sentry from "@sentry/vue";
import { FetchError } from "ofetch";

export const useErrorHandler = (error: FetchError) => {
  console.error(error);

  if (
    process.env.NODE_ENV === "production" &&
    process.client &&
    error.statusCode !== 401 &&
    error.statusCode !== 403 &&
    error.statusCode !== 429
  ) {
    Sentry.captureException(error);
  }

  const _error = error as any;

  const errorMessage = getErrorMessage(_error);

  return errorMessage;
};

function getErrorMessage(error: FetchError): string {
  const defaultErrorMsg = "An unknown error occured.";

  try {
    if (error.statusCode === 429) {
      // Handle rate limit
      return typeof error?.data === "string"
        ? error?.data
        : "Too many requests. Please wait 10 seconds before trying again.";
    }

    if (error.data) {
      // Handle error message from server
      const responseErrorMsg =
        error?.data?.error?.message || error?.data?.message;

      const errorMsgProvided = typeof responseErrorMsg === "string";

      return errorMsgProvided ? responseErrorMsg : defaultErrorMsg;
    }

    // Network Error
    let isOnline = true;

    if (process.client && window && window.navigator) {
      isOnline = window.navigator.onLine;
    }
    return isOnline
      ? "The server is currently not reachable"
      : "You are offline and can't connect to the server.";
  } catch (err: any) {
    if (process.env.NODE_ENV !== "production") {
      console.error("While handling this error another error occured.");
      console.error(error);
      console.error(err);
    }
  }

  return defaultErrorMsg;
}
